import React from "react";
import styled from "@emotion/styled";
import { css, SerializedStyles } from "@emotion/react";
import { Tag as TagGraphic } from "./Tag";
import { backgroundMapper, BackgroundVariantProps } from "../Section";
import { Button } from "../Button";
import { Typography } from "../Typography";
import { desktopUp, mobileDown } from "../theme/ecologi-breakpoints";
import { isZeroRoadmap } from "../utils/is-zero-roadmap";
import * as bp from "@ecologi/react-components/src/theme/ecologi-breakpoints";

type CardVariant = "default" | "section" | "compact" | "profile" | "zero";

export type CardVariantProps = {
  variant?: CardVariant;
  isImpactPage?: boolean;
  slug?: string | string[];
};

type VariantsMap = { [name in CardVariant]: SerializedStyles };

const MW_415 = `only screen and (min-width: 415px)`;
const MW_630 = `only screen and (min-width: 630px)`;
const MW_705 = `only screen and (min-width: 706px)`;
const MW_1024 = `only screen and (min-width: 1024px)`;

const containerVariants = (theme: any): VariantsMap => ({
  default: css``,
  compact: css``,
  section: css`
    background: ${theme.colors.snowdrop};

    @media ${theme.bp.tabletUp} {
      grid-template-columns: 1fr 1fr;
      display: grid;
    }
  `,
  profile: css``,
  zero: css``,
});

const zeroVariants = {
  zero_first: css`
    background-color: #ecf4f4;
    border-radius: 5px;
    padding: 5px 20px 12px;
  `,
  zero_second: css`
    border-radius: 5px;
  `,
  zero_third: css`
    border-radius: 5px;
    padding-right: 20px;
    padding-left: 20px;
  `,
};

type CardContainerProps = CardVariantProps &
  BackgroundVariantProps & { isImpactCard?: boolean };

export const CardContainer = styled.div<CardContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: ${(props) =>
    props?.backgroundVariant && backgroundMapper[props?.backgroundVariant]
      ? backgroundMapper[props?.backgroundVariant](props.theme)
      : props.theme.colors.mist};

  ${(props) => containerVariants(props.theme)[props.variant || "default"]}

  ${(props) =>
    props?.isImpactCard
      ? css`
          @media ${desktopUp} {
            padding: 60px 10px;
          }
          @media ${mobileDown} {
            padding: 20px 0;
          }
        `
      : css``}
  ${(props) => zeroVariants[props?.slug ? (props.slug as string) : ""]}
`;

const contentVariants = (theme: any): VariantsMap => ({
  default: css``,
  compact: css`
    padding-bottom: 0px;
  `,
  section: css`
    @media ${theme.bp.tabletUp} {
      padding: 35px 55px;
    }
  `,
  profile: css`
    width: 100%;
    background-color: ${theme.colors.snowdrop};
    padding: unset;
    padding-top: 16px;
    @media ${theme.bp.extraSmallUp} {
      width: 261px;
    }
  `,
  zero: css``,
});

const zeroContentVariants = {
  zero_third: css`
    padding-top: 0px;
  `,
};

export const CardContent = styled.div<CardVariantProps>`
  padding: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  ${(props) => contentVariants(props.theme)[props.variant || "default"]}
  ${(props) => zeroContentVariants[props.slug as string]};
`;

const titleVariants = (theme: any): VariantsMap => ({
  default: css``,
  compact: css``,

  section: css`
    @media ${theme.bp.tabletUp} {
      text-align: left;
    }
  `,
  profile: css`
    text-align: left;
  `,
  zero: css``,
});

export const CardTitle = styled.h2<CardVariantProps & { title_color?: string }>`
  color: ${(props) => props.theme.colors.charcoal};
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0px;
  overflow-wrap: break-word;
  line-height: 1;
  margin-bottom: 10px;

  ${(props) => titleVariants(props.theme)[props.variant || "default"]}

  ${(props) =>
    props?.isImpactPage
      ? css`
          @media ${mobileDown} {
            font-size: 22px;
          }
        `
      : css``}
      
      ${(props) =>
    props.title_color !== "CHARCOAL" && !!props.title_color
      ? css`
          color: ${backgroundMapper[props.title_color as string](props.theme)};
        `
      : css``}
  ${(props) => zeroTitleVariants[props.slug as string]};
`;

const textVariants = (theme: any): VariantsMap => ({
  default: css``,
  compact: css``,
  section: css`
    @media ${theme.bp.tabletUp} {
      text-align: left;
      padding: 0;
    }
  `,
  profile: css`
    text-align: left;
  `,
  zero: css``,
});

const zeroTitleVariants = {
  zero_third: css`
    margin-bottom: 25px;
    @media ${bp.mobileDown} {
      font-size: 20px;
    }
  `,
};

const zeroTextVariants = {
  zero_first: css`
    font-size: 16px;
    @media ${bp.mobileDown} {
      font-size: 14.5px;
    }
  `,
  zero_third: css`
    font-size: 16px;
    @media ${bp.mobileDown} {
      font-size: 14.5px;
    }
  `,
};

export const CardText = styled.p<CardVariantProps>`
  color: ${(props) => props.theme.colors.charcoal};
  text-align: center;
  font-size: 18px;
  line-height: 1.4;
  margin-bottom: 10px;
  padding: 0 2px;

  ${(props) => textVariants(props.theme)[props.variant || "default"]}

  a {
    color: ${(props) => props.theme.colors.jungle};
    text-decoration: none;
  }
  ${(props) => zeroTextVariants[props.slug as string]}
`;

export const CardLink = styled(Typography)`
  color: ${({ theme }) => theme.colors.charcoal};
`;

export const CardLinkText = styled(CardText)<CardVariantProps>`
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
  display: block;
`;

const actionVariants = (theme: any): VariantsMap => ({
  default: css``,
  compact: css``,
  section: css`
    @media ${theme.bp.tabletUp} {
      margin-top: 30px;
      justify-content: flex-start;
    }
  `,
  profile: css``,
  zero: css``,
});

export const CardActions = styled.div<CardVariantProps>`
  margin-top: 15px;
  flex-grow: 1;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  ${(props) => actionVariants(props.theme)[props.variant || "default"]}

  button {
    width: 100%;

    @media ${(props) => props.theme.bp.tabletUp} {
      width: initial;
    }
  }
`;

const imageVariants = (theme: any): VariantsMap => ({
  default: css``,
  compact: css``,
  section: css`
    @media ${theme.bp.tabletUp} {
      height: 100%;
    }
  `,
  profile: css`
    height: 261px;
    width: 100%;
    background-size: unset;
    background-color: ${theme.colors.snowdrop};
    border: 2px solid ${theme.colors.mist};
  `,
  zero: css`
    width: 60%;
    margin: auto;
  `,
});

const zeroImageVariants = {
  zero_first: css`
    width: 100%;
    @media ${MW_415} {
      width: 60%;
    }
    @media ${MW_630} {
      width: 50%;
    }
    @media ${MW_705} {
      width: 80%;
    }
    @media ${MW_1024} {
      width: 100%;
    }
  `,
};

const BackgroundImage = styled.div<CardVariantProps>`
  background: ${(props) => props.theme.colors.charcoal};
  background: linear-gradient(
    180deg,
    ${(props) => props.theme.colors.charcoal} 0%,
    ${(props) => props.theme.colors.charcoal} 30%,
    rgba(50, 50, 50, 1) 100%
  );

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: ${(props) => (props?.isImpactPage ? 150 : 200)}px;
  width: ${(props) => (props?.isImpactPage ? 70 : 100)}%;
  margin: ${(props) => (props?.isImpactPage ? "auto" : "")};

  ${(props) => imageVariants(props.theme)[props.variant || "default"]};
  ${(props) => zeroImageVariants[props?.slug ? (props.slug as string) : ""]}
`;

interface ImageProps {
  imageUrl?: string;
}

export const CardImage = ({
  imageUrl,
  variant,
  isImpactPage,
  slug,
}: ImageProps & CardVariantProps) => {
  return (
    <BackgroundImage
      isImpactPage={isImpactPage}
      variant={variant}
      style={imageUrl ? { backgroundImage: `url(${imageUrl})` } : {}}
      slug={slug}
    />
  );
};

const Tag = styled(TagGraphic)`
  position: absolute;
  top: 19px;
  left: 0;
`;

export type BaseCardProps = {
  title: string;
  text: string;
  imageUrl: string;
} & BackgroundVariantProps;

export type CardProps = {
  linkText?: string;
  tagText?: string;
  ctaText?: string;
  onClickCTA?: (event: React.MouseEvent<HTMLElement>) => void;
  onClickLink?: (event: React.MouseEvent<HTMLElement>) => void;
  title_color?: string;
  extra?: {
    href?: string;
    rel?: string;
    target?: "_blank";
    title?: string;
  };
} & CardVariantProps &
  BaseCardProps &
  BackgroundVariantProps;

const impactCardsTitles = ["1. Sign up", "2. Start funding", "3. Access tools"];

export function Card({
  variant,
  title,
  text,
  imageUrl,
  linkText,
  tagText,
  ctaText,
  onClickCTA,
  onClickLink,
  extra,
  isImpactPage,
  title_color,
  slug,
  ...rootProps
}: CardProps) {
  return (
    <CardContainer
      {...rootProps}
      variant={variant}
      isImpactCard={!!impactCardsTitles.includes(title)}
      slug={slug}
    >
      {!isZeroRoadmap(slug) && (
        <CardImage
          imageUrl={imageUrl}
          variant={variant}
          isImpactPage={isImpactPage}
          slug={slug}
        />
      )}
      <CardContent variant={variant} slug={slug}>
        <CardTitle
          variant={variant}
          isImpactPage={isImpactPage}
          title_color={title_color}
          slug={slug}
        >
          {title}
        </CardTitle>
        {tagText && <Tag text={tagText} />}
        <CardText
          // The default for HTML element for `<CardText /> is a <p />
          // Use a <div /> when passing WSYWIG content as dangerouslySetInnerHTML.
          // This is because rendering a <p /> inside a <p /> fails
          as="div"
          variant={variant}
          dangerouslySetInnerHTML={{ __html: text }}
          slug={slug}
        />

        {onClickLink && (
          <CardLinkText onClick={onClickLink} variant={variant}>
            {linkText}
          </CardLinkText>
        )}
        {extra && (
          <CardLink use="inlineLink" {...extra}>
            {linkText}
          </CardLink>
        )}

        {onClickCTA && ctaText && (
          <CardActions variant={variant}>
            <Button onClick={onClickCTA} green text={ctaText} />
          </CardActions>
        )}
      </CardContent>
    </CardContainer>
  );
}
