import React from "react";
import styled from "@emotion/styled";

const TagPath = styled.path`
  fill: #f2f2f2;
`;

const Path = styled.path`
  fill: #fff;
`;

export function TagGraphic(props: { className?: string }) {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="155"
      height="46"
      viewBox="0 0 155 46"
    >
      <g transform="translate(-215 -626)">
        <TagPath
          d="M2903.923,1062.3l-11.936,14.452a10,10,0,0,0,0,12.738l11.936,14.452a6,6,0,0,0,4.626,2.179H3020.7a4,4,0,0,0,4-4v-38a4,4,0,0,0-4-4H2908.549A6,6,0,0,0,2903.923,1062.3Zm-3.035,25.821a5,5,0,1,1,5-5A5,5,0,0,1,2900.888,1088.123Z"
          transform="translate(-2654.697 -434.123)"
        />

        <Path
          d="M0,0H31a1,1,0,0,1,1,1V1a1,1,0,0,1-1,1H0A0,0,0,0,1,0,2V0A0,0,0,0,1,0,0Z"
          transform="translate(215 648)"
        />
      </g>
    </svg>
  );
}

const TagText = styled.p`
  font-size: 28px;
  font-family: SofiaPro-Bold, Sofia Pro;
  font-weight: 700;
  isolation: isolate;
  background: #f2f2f2;
  position: absolute;
  padding: 12px 10px 14px 10px;
  top: 0;
  left: 38px;
  border-radius: 5px;
  min-width: 119px;
  text-align: center;
  box-sizing: border-box;
`;

const TagRoot = styled.span``;

export function Tag({
  text,
  ...rootProps
}: {
  text: string;
  className?: string;
}) {
  return (
    <TagRoot {...rootProps}>
      <TagGraphic />
      <TagText>{text}</TagText>
    </TagRoot>
  );
}
